import React from 'react'
import PropTypes from 'prop-types';

const TwitterShare = (props) => {
  const twitterUrl = 'https://twitter.com/intent/tweet/'

  let shareUrl = `${twitterUrl}?&url=${encodeURIComponent(props.url)}&related=wyeworks`
  shareUrl = props.by ? `${shareUrl}&via=${props.by}` : shareUrl
  shareUrl = props.text ? `${shareUrl}&text=${
    encodeURIComponent(props.text)
  }` : shareUrl

  return (
    <a
      className={`social-layout-1__icon ${props.alt ? 'social-layout-1__icon--alt' : ''}`}
      href={shareUrl}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="share on Twitter"
    >
      <div className="icon-Twitter-icon"></div>
    </a>
  )
}

TwitterShare.propTypes = {
  url: PropTypes.string.isRequired,
  by: PropTypes.string,
  text: PropTypes.string,
  alt: PropTypes.bool
}

export default TwitterShare
