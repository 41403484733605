import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "@components/layout"
import FacebookShare from "@components/fb-share-button"
import TwitterShare from "@components/tw-share-button"
import { DiscussionEmbed } from "disqus-react";

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showComments: false
    }
  }

  displayComments() {
    this.setState({
      showComments: true
    })
  }

  render() {
    const post = this.props.data.markdownRemark
    const siteMeta = this.props.data.site.siteMetadata
    const { title, author } = post.frontmatter
    const readingTime = post.fields.readingTime.text
    const { date, slug } = post.fields
    const thumbnail = post.frontmatter.thumbnail || siteMeta.defaultPostThumbnail
    const shareUrl = siteMeta.siteUrl + slug
    const disqusConfig = {
      url: shareUrl,
      identifier: post.id,
      title,
    }

    return (
      <Layout
        location={this.props.location}
        title={title}
        namespace='page'
        description={post.excerpt}
        thumbnail={thumbnail}
        author={author.twitter_handle}
      >
        <div className='hero article-hero'>
          <div className='article-hero__feature-gradient'></div>
          <div className='article-hero__feature-image'>
            <img src={thumbnail} alt='' />
          </div>
          <div className='article-hero__primary-spacer'></div>
          <div className='article-hero__primary-block'>
            <div className='primary-block__pattern-1'>
              <img src='/images/blog-article-pattern1.png' alt='' />
            </div>
            <div className='primary-block__pattern-2'>
              <img src='/images/blog-article-pattern2.png' alt='' />
            </div>
            <div className='primary-block__container'>
              <div className='primary-block__content-wrapper'>
                <div className='content-block-2__title-label'>
                  <h2 className='h2-label'>
                    <div className='h2-label__title'>
                      Blog article
                    </div>
                    <div className='h2-label__seperator'></div>
                  </h2>
                </div>
                <div className='social-layout-1 social-layout-1--alt'>
                  <FacebookShare url={shareUrl} alt />
                  <TwitterShare url={shareUrl} text={title} by={author.twitter_handle} alt />
                  <div className='social-layout-1__seperator social-layout-1__seperator--alt'></div>
                  Share This Article
                </div>
              </div>
            </div>
          </div>
          <div className='article-hero__article-info'>
            <div className='article-info__content-wrapper'>
              <h3 className='article-info__title'>
                {title}
              </h3>
              <div className='article-info__article-meta'>
                <div className='article-meta__avatar'>
                  <img src={author.image} alt='' />
                </div>
                <div className='article-meta__author'>
                  {author.name}
                </div>
                <div className='article-meta__sep'></div>
                <div className='article-meta__date'>
                  {date}
                </div>
                <div className='article-meta__sep2'></div>
                <div className='article-meta__read-time'>
                  {readingTime}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className='article-content'>
          <div className='long-content__wrapper' dangerouslySetInnerHTML={{ __html: post.html }} />
        </section>

        <section className='article-comments'>
          <div className='long-content__wrapper'>
            {
              this.state.showComments ?
                <DiscussionEmbed shortname='wyeblog' config={disqusConfig} /> :
                <button className='show-comments-button' onClick={() => this.displayComments()}>Show Comments</button>
            }
          </div>
        </section>

        <div className='blog-listings__more-articles'>
          <Link className='primary-button' to='/blog'>
            Back to articles
            <div className='primary-button__icon'>
              <div className='icon-right-arrow-icon'></div>
            </div>
          </Link>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author,
        defaultPostThumbnail,
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      fields {
        slug
        readingTime {
          text
        }
        date(formatString: "MMM DD, YYYY")
      }
      frontmatter {
        title
        author {
          name
          image
          twitter_handle
        }
        thumbnail
      }
    }
  }
`
