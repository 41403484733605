import React from 'react'
import PropTypes from 'prop-types';

const FacebookShare = (props) => {
  const facebookUrl = 'https://facebook.com/sharer/sharer.php'
  const shareUrl = `${facebookUrl}?u=${props.url}`

  return (
    <a
      className={`social-layout-1__icon ${props.alt ? 'social-layout-1__icon--alt' : ''}`}
      href={shareUrl}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="share on Facebook"
    >
      <div className='icon-Facebook-icon'></div>
    </a>
  )
}

FacebookShare.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.bool
}

export default FacebookShare
